/* OBLULENIE */

* {
    padding: 0;
    margin: 0;
    border: 0;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    /*font-size: 100%;*/
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-test-size-adjust: 100%;
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: 400;
}

/* MAIN */

:root {
    --primary-color: #faf9f6;
    --secondory-color: #11242f;
    --third-color: #353c4e;
    --fourth-color: #fff;
    --fifth-color-: #353c4e;
    --six-color: #193748;
    --seven-color-: #11242f;
    --eight-color: #193748;
    --nine-color: #11242f;
    --ten-color-: #e0e0e0;
    --eleven-color-: #919aa3;
    --twelwe-color-: #000;
    --thirteen-color-: #919aa3;
    --fourteen-color: rgba(69, 90, 100, 0.048);
    --gradient-color: linear-gradient(to right, #01a9ac, #01dbdf);
    --gradient-color2: linear-gradient(to right, #00bbbe, #01dbdf);
    --gradient-color3: linear-gradient(#01a9ac, #01dbdf);
    --status-gradient1: linear-gradient(
            rgba(255, 0, 0, 0.055),
            rgba(255, 0, 0, 0.274)
    );
    --status-gradient2: linear-gradient(
            rgba(0, 255, 115, 0.082),
            rgba(0, 255, 115, 0.329)
    );
    --shadow-color-: rgba(69, 90, 100, 0.048);
    --shadow-color2-: rgba(31, 196, 207, 0.493);
    --visa: navy;
    --amex: blue;
    --mastercard: red;
    --discover: orange;
}

.dark-theme {
    --primary-color: #33393d;
    --secondory-color: #1d1f22;
    --third-color: #01dbdf;
    --fourth-color: rgba(255, 255, 255, 0.836);
    --fifth-color-: rgb(214, 214, 214);
    --six-color: #b9d5dd13;
    --seven-color-: #b9d5dd13;
    --eight-color: #1d1f22;
    --nine-color: #01dbdf;
    --ten-color-: #01dbdf34;
    --eleven-color-: #afb7be;
    --twelwe-color-: rgb(214, 214, 214);
    --thirteen-color-: rgba(214, 214, 214, 0.644);
    --fourteen-color: rgba(42, 123, 129, 0.452);
    --gradient-color: linear-gradient(to right, #027c7e, #02aeb1);
    --gradient-color2: linear-gradient(to right, #03898b, #02b8bb);
    --gradient-color3: linear-gradient(#027c7e, #02aeb1);
    --status-gradient1: linear-gradient(
            rgba(255, 0, 0, 0.096),
            rgba(255, 0, 0, 0.514)
    );
    --status-gradient2: linear-gradient(
            rgba(0, 255, 115, 0.13),
            rgba(0, 255, 115, 0.493)
    );
    --shadow-color-: rgba(42, 123, 129, 0.452);
    --shadow-color2-: rgba(188, 219, 221, 0.61);
    --visa: rgb(0, 0, 73);
    --amex: rgb(1, 1, 128);
    --mastercard: rgb(110, 0, 0);
    --discover: rgb(165, 107, 0);
}

#container {
    position: relative;
    min-height: 100%;
}

.wrapper {
    padding-bottom: 2.5rem;
}

body {
    background-color: var(--primary-color);
    overflow-y: hidden;
    font-family: "Montserrat", sans-serif;
}

.signin {
    height: 100vh;
    background: url("../images/background-girl.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.jbox-dark-sm {
    box-sizing: border-box;
    width: 100%;
    max-width: 580px;
    height: 680px;
    top: 15%;
    margin: auto;
    position: relative;
    padding: 5px;
    background: #00132980;
    overflow: hidden;
}

.jbox-dark-header {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-size: 40px;
    font-weight: 500;
}

.jbox-dark-paragraph {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    margin: 25px;
    line-height: 30px;
}

hr.solid {
    border-top: 3px solid #10cbafee;
    width: 80px;
    margin: 25px auto;
}

.button-box {
    width: 440px;
    margin: 35px auto 40px auto;
    position: relative;
    box-shadow: 0 0 2px 2px #10cbafee;
}

.button-box .toggle-btn {
    padding: 20px 50px 20px 73px;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: none;
    position: relative;
    font-size: 22px;
    color: #fff;
}

#signin-btn {
    position: absolute;
    width: 220px;
    height: 100%;
    background: linear-gradient(to right, #0cebc9ee, #55c9f7c4);
}

.input-group {
    position: absolute;
    width: 440px;
    transition: 0.5s;
}

.input-group .input-field {
    color: #fff;
    font-size: 20px;
    width: 100%;
    padding: 15px 0;
    margin: 5px 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.842);
    outline: none;
    background: transparent;
}

.input-group .input-field::placeholder {
    color: rgba(255, 255, 255, 0.842);
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.input-group .submit-btn {
    font-size: 22px;
    color: #fff;
    width: 200px;
    padding: 20px 40px;
    cursor: pointer;
    display: block;
    margin: auto;
    margin-top: 10%;
    background: linear-gradient(to right, #0cebc9ee, #55c9f7c4);
    border: 0;
    outline: none;
}

.input-group .submit-btn:hover {
    background: linear-gradient(to right, #09d4b6ee, #48acd3c4);
}

#signin-patient {
    left: 70px;
}

#signin-physician {
    left: 600px;
}

.button-box-phone {
    display: none;
    width: 350px;
    margin: 35px auto 40px auto;
    position: relative;
    box-shadow: 0 0 2px 2px #10cbafee;
}

.button-box-phone .toggle-btn-phone {
    padding: 20px 40px 20px 45px;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: none;
    position: relative;
    font-size: 22px;
    color: #fff;
}

#signin-btn-phone {
    position: absolute;
    width: 175px;
    height: 100%;
    background: linear-gradient(to right, #0cebc9ee, #55c9f7c4);
}

.input-group-phone {
    display: none;
    position: relative;
    width: 350px;
    transition: 0.5s;
}

.input-group-phone .input-field-phone {
    color: #fff;
    font-size: 20px;
    width: 100%;
    padding: 15px 0;
    margin: 5px 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.842);
    outline: none;
    background: transparent;
}

.input-group-phone .input-field-phone::placeholder {
    color: rgba(255, 255, 255, 0.842);
}

.input-group-phone .submit-btn-phone {
    font-size: 22px;
    color: #fff;
    width: 200px;
    padding: 20px 40px;
    cursor: pointer;
    display: block;
    margin: auto;
    margin-top: 10%;
    background: linear-gradient(to right, #0cebc9ee, #55c9f7c4);
    border: 0;
    outline: none;
}

.input-group-phone .submit-btn:hover {
    background: linear-gradient(to right, #09d4b6ee, #48acd3c4);
}

#signin-patient-phone {
    margin: auto;
    left: 0;
}

#signin-physician-phone {
    margin: auto;
    left: 600px;
    top: -342px;
}

.jbox-dark-md {
    box-sizing: border-box;
    width: 100%;
    max-width: 1110px;
    /*height: 820px;*/
    height: 82vh;
    top: 8%;
    margin: auto;
    position: relative;
    padding: 5px;
    background: #00132980;
    overflow: hidden;
}

.jbox-dark-md .jbox-dark-md-header {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-size: 40px;
    font-weight: 500;
}

#register-patient {
    left: 70px;
}

#register-physician {
    left: 1110px;
}

#register-btn {
    position: absolute;
    width: 220px;
    height: 100%;
    background: linear-gradient(to right, #0cebc9ee, #55c9f7c4);
}

.row {
    display: flex;
}

.column {
    flex: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.column-phone {
    display: none;
}

.column-phone2 {
    display: none;
}

.input-group-md {
    position: absolute;
    transition: 0.5s;
}

.input-group-md .input-field {
    color: #fff;
    font-size: 20px;
    width: 450px;
    padding: 15px 0;
    margin: 5px 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.842);
    outline: none;
    background: transparent;
}

.input-group-md .input-field::placeholder {
    color: rgba(255, 255, 255, 0.842);
}

.input-group-md .submit-btn {
    font-size: 22px;
    color: #fff;
    width: 200px;
    padding: 20px 40px;
    cursor: pointer;
    display: block;
    margin-left: 37%;
    margin-top: 5%;
    background: linear-gradient(to right, #0cebc9ee, #55c9f7c4);
    border: 0;
    outline: none;
}

.jbox-dark-xs {
    box-sizing: border-box;
    width: 100%;
    max-width: 580px;
    height: 430px;
    top: 15%;
    margin: auto;
    position: relative;
    padding: 5px;
    background: #00132980;
    overflow: hidden;
}

.jbar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background: var(--secondory-color);
    z-index: 100;
    transition: all 0.5s ease;
}

.logo {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    width: 75%;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
}

.logo2 {
    display: flex;
    justify-content: space-between;
}

.logo2 img {
    width: 160px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
}

.nav-links {
    background: var(--secondory-color);
    height: 100%;
    padding-top: 30px;
    overflow: auto;
}

.nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.5s ease;
}

.nav-links li:hover {
    background: var(--six-color);
}

.nav-links li .icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-links li i {
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color: var(--fourth-color);
    font-size: 22px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.jbar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
}

.jbar .nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.jbar .nav-links li a .link-name {
    font-size: 20px;
    font-weight: 400;
    color: var(--fourth-color);
    font-family: "Montserrat", sans-serif;
}

.jbar .nav-links li .sub-menu {
    padding: 6px 6px 14px 80px;
    margin-top: -10px;
    background: var(--six-color);
    display: none;
}

.jbar .nav-links li.showMenu .sub-menu {
    display: block;
}

.jbar .nav-links li .sub-menu a {
    color: var(--fourth-color);
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.jbar .nav-links li .sub-menu a:hover {
    opacity: 1;
}

.jbar .nav-links li .sub-menu .link-name {
    display: none;
}

.dark-theme-hr {
    border-top: 5px solid var(--seven-color-);
    width: 260px;
    position: fixed;
    bottom: 64px;
}

.jbar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--eight-color);
    padding: 6px 0;
    transition: all 0.5s ease;
}

.jbar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.jbar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    padding: 10px;
}

.jbar .profile-details .name,
.jbar .profile-details .id {
    font-family: "Montserrat", sans-serif;
    color: var(--fourth-color);
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.jbar .profile-details .id {
    font-size: 12px;
}

#icon {
    width: 28px;
    cursor: pointer;
    margin: 10px 0 0 25px;
}

.jbox {
    overflow: auto;
    max-height: 83vh;

    position: absolute;
    left: 310px;
    top: 110px;
    width: 70%;
    display: flex;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 10px 10px 15px 1px var(--shadow-color-);
}

.jbox .left {
    width: 50px;
    background: var(--gradient-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.jbox .right {
    width: 100%;
    background: transparent;
    padding: 25px;
}

.jbox .right .info {
    margin-bottom: 45px;
}

.jbox .right .info h3 {
    font-size: 40px;
    margin-bottom: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--ten-color-);
    color: var(--third-color);
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 500;
}

.jbox .right .info p {
    font-size: 22px;
    margin-top: 15px;
    padding-bottom: 15px;
    color: var(--third-color);
    text-transform: capitalize;
    font-weight: 500;
}

.jbox .right .info h3 i {
    font-size: 20px;
    color: #01a9ac;
    margin-left: 70%;
}

.jbox .right .info .info-data {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.jbox .right .info .info-data .data {
    width: 100%;
}

.jbox .right .info .info-data .data h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: var(--eleven-color-);
}

.jbox .right .info .info-data .data p {
    color: var(--fifth-color-);
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
}

.jbox .right .info .info-data .data .fa-pencil {
    display: none;
}

.jbox .right .info .info-data .data .sm-input {
    width: 250px;
    background-color: transparent;
    border: 2px solid #01a9ac;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
}

.jbox .right .info .info-data .data .sm-input::placeholder {
    color: var(--thirteen-color-);
}

.jbox .right .info .info-data .data .sm-input,
select,
textarea {
    color: var(--twelwe-color-);
}

.jbox .right .info .info-data .data .sm-input:focus {
    border: 2px solid #019092;
    outline: none;
}

.jbox .right .info .info-data .data .data-input {
    width: 350px;
    background-color: transparent;
    border: 2px solid #01a9ac;
    font-size: 18px;
    padding: 15px;
    margin: 10px 0 0 0;
    border-radius: 5px;
}

.jbox .right .info .info-data .data .data-input::placeholder {
    color: var(--thirteen-color-);
}

.jbox .right .info .info-data .data .data-input,
select,
textarea {
    color: var(--twelwe-color-);
}

.jbox .right .info .info-data .data .data-input:focus {
    border: 2px solid #019092;
    outline: none;
}

.jbox .right .info .info-data .data .data-btn {
    width: 130px;
    background: var(--gradient-color2);
    color: #fff;
    font-size: 18px;
    padding: 17px;
    margin: 10px 0 0 20px;
    border-radius: 5px;
}

.jbox .right .info .info-data .data .data-btn:hover {
    background: linear-gradient(to right, #00a5a8, #00c4c7);
}

.jbox .pdf ul {
    display: flex;
}

.jbox .pdf ul li {
    width: 35px;
    height: 35px;
    background: var(--gradient-color2);
    margin-right: 10px;
    border-radius: 5px;
    line-height: 35px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 16px;
}

.accordion {
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.accordion .accordion-content {
    position: relative;
    margin: 10px 0 0 0;
}

.accordion .accordion-content .accordion-label {
    position: relative;
    width: 100%;
    padding: 10px;
    background: transparent;
    color: var(--third-color);
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
}

.accordion-label .fa-angle-down {
    margin-right: 15px;
}

.accordion .accordion-content .accordion-box {
    position: relative;
    background: transparent;
    height: 0;
    overflow: hidden;
    overflow-y: auto;
}

.accordion .accordion-content.active .accordion-box {
    height: auto;
    padding: 10px 10px 25px 10px;
    border-bottom: 1px solid var(--ten-color-);
}

.accordion .accordion-content .accordion-box p {
    color: var(--twelwe-color-);
}

/*.payment-form {*/
/*    box-sizing: border-box;*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.col-25 {*/
/*    flex: 35%;*/
/*}*/

/*.col-50 {*/
/*    flex: 50%;*/
/*}*/

/*.col-75 {*/
/*    flex: 65%;*/
/*}*/

/*.cart {*/
/*    margin: 80px 3% 0 10%;*/
/*    padding: 30px 30px 0 30px;*/
/*}*/

/*.cart a {*/
/*    outline: none;*/
/*    color: var(--fifth-color-);*/
/*}*/

/*.cart hr {*/
/*    border-bottom: 1px solid var(--ten-color-);*/
/*    margin: 15px;*/
/*}*/

/*.icon-container {*/
/*    padding: 7px 0;*/
/*    font-size: 24px;*/
/*}*/

/*.payment-input {*/
/*    width: 100%;*/
/*    background-color: transparent;*/
/*    border: 2px solid #01a9ac;*/
/*    font-size: 14px;*/
/*    padding: 12px;*/
/*    margin: 10px 0 10px 0;*/
/*    border-radius: 5px;*/
/*}*/

/*.payment-input::placeholder {*/
/*    color: var(--thirteen-color-);*/
/*}*/

/*.payment-input,*/
/*select,*/
/*textarea {*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.payment-input:focus {*/
/*    border: 2px solid #019092;*/
/*    outline: none;*/
/*}*/

/*span.price {*/
/*    float: right;*/
/*    color: var(--thirteen-color-);*/
/*}*/

/*.payment-btn {*/
/*    width: 130px;*/
/*    background: var(--gradient-color2);*/
/*    color: #fff;*/
/*    font-size: 16px;*/
/*    padding: 9px;*/
/*    margin: 10px 0 0 0;*/
/*    border-radius: 5px;*/
/*}*/

/*.payment-btn:hover {*/
/*    background: linear-gradient(to right, #00a5a8, #00c4c7);*/
/*}*/

/*.block-btn {*/
/*    cursor: not-allowed;*/
/*    opacity: 0.5;*/
/*}*/

/*.fa-cc-visa {*/
/*    color: var(--visa);*/
/*}*/

/*.fa-cc-amex {*/
/*    color: var(--amex);*/
/*}*/

/*.fa-cc-mastercard {*/
/*    color: var(--mastercard);*/
/*}*/

/*.fa-cc-discover {*/
/*    color: var(--discover);*/
/*}*/

/*.history {*/
/*    position: absolute;*/
/*    left: 310px;*/
/*    top: 110px;*/
/*    width: 70%;*/
/*    box-shadow: 10px 10px 15px 1px var(--shadow-color-);*/
/*    border-top-left-radius: 5px;*/
/*    border-top-right-radius: 5px;*/
/*    border-collapse: collapse;*/
/*    text-align: center;*/
/*    overflow: hidden;*/
/*}*/

/*.history thead {*/
/*    background: var(--gradient-color3);*/
/*    color: #fff;*/
/*}*/

/*.history th {*/
/*    padding: 16px;*/
/*    text-transform: uppercase;*/
/*    letter-spacing: 0.1rem;*/
/*    font-size: 14px;*/
/*    font-weight: 600;*/
/*}*/

/*.history td {*/
/*    padding: 16px;*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.history a {*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*}*/

/*.history .status {*/
/*    border-radius: 5px;*/
/*    background: var(--status-gradient1);*/
/*    padding: 10px 0px;*/
/*    text-align: center;*/
/*}*/

/*.history .delivered {*/
/*    background: var(--status-gradient2);*/
/*}*/

/*.history tr:nth-child(even) {*/
/*    background-color: var(--fourteen-color);*/
/*}*/

/*.product-container {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    flex-wrap: wrap;*/
/*    box-sizing: border-box;*/
/*    margin-left: -15px;*/
/*}*/

/*.product-container .product-box {*/
/*    position: relative;*/
/*    width: 250px;*/
/*    !*height: 200px;*!*/
/*    height: 290px;*/
/*    margin: 30px 60px 0 0;*/
/*    transition: 0.5s;*/
/*}*/

/*.product-container .product-box:hover {*/
/*    height: 250px;*/
/*    margin-bottom: 40px;*/
/*    cursor: pointer;*/
/*}*/

/*.product-container .product-box .product-quantity {*/
/*    position: relative;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    top: 175px;*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.product-container .product-box .product-img {*/
/*    position: absolute;*/
/*    !*display: block;*!*/
/*    !*margin: 0 auto;*!*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.product-container .product-box .product-img img {*/
/*    max-width: 100%;*/
/*    margin-top: 10px;*/
/*    opacity: 0.5;*/
/*    transition: 0.5s;*/
/*}*/

/*.product-container .product-box:hover .product-img img {*/
/*    filter: drop-shadow(0 0 50px var(--shadow-color2-));*/
/*    transition: 0.5s;*/
/*    opacity: 1;*/
/*}*/

/*.product-container .product-box::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: -2px;*/
/*    left: -2px;*/
/*    right: -2px;*/
/*    bottom: -2px;*/
/*    z-index: -1;*/
/*}*/

/*.product-container .product-box::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: -2px;*/
/*    left: -2px;*/
/*    right: -2px;*/
/*    bottom: -2px;*/
/*    z-index: -2;*/
/*}*/

/*.product-container .product-box .product-content {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    left: 10px;*/
/*    right: 10px;*/
/*    bottom: 10px;*/
/*    height: 10px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    opacity: 0;*/
/*    transition: 0.5s;*/
/*}*/

/*.product-container .product-box .product-content .product-input {*/
/*    width: 50px;*/
/*    border: 1.5px solid #01a9ac;*/
/*    font-size: 14px;*/
/*    padding: 5px;*/
/*    margin-left: 10px;*/
/*    border-radius: 5px;*/
/*    background: transparent;*/
/*}*/

/*.product-container .product-box .product-content .product-input:focus {*/
/*    border: 1.5px solid #019092;*/
/*    outline: none;*/
/*}*/

/*.product-container .product-box:hover .product-content {*/
/*    opacity: 1;*/
/*}*/

/*.product-container .product-box h2 {*/
/*    font-size: 16px;*/
/*    font-weight: 600;*/
/*    line-height: 20px;*/
/*    letter-spacing: 1px;*/
/*    text-align: center;*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.product-container .product-box h2 span {*/
/*    font-size: 16px;*/
/*    font-weight: 200;*/
/*    letter-spacing: 2px;*/
/*}*/

/*.product-container .product-box h2 i {*/
/*    margin-left: 15px;*/
/*}*/

/*.nav {*/
/*    display: none;*/
/*}*/

/*.header {*/
/*    display: none;*/
/*}*/

/*.jbox_reduced {*/
/*    position: absolute;*/
/*    left: 310px;*/
/*    top: 110px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    border-top-right-radius: 5px;*/
/*    border-bottom-right-radius: 5px;*/
/*    box-shadow: 10px 10px 15px 1px var(--shadow-color-);*/
/*    margin-right: 5%;*/
/*}*/

/*.jbox_reduced .left_reduced {*/
/*    width: 20px;*/
/*    background: var(--gradient-color);*/
/*    border-top-left-radius: 5px;*/
/*    border-bottom-left-radius: 5px;*/
/*}*/

/*.jbox_reduced .right_reduced {*/
/*    width: 35%;*/
/*    background: transparent;*/
/*    padding: 25px;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced {*/
/*    margin-bottom: 25px;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced h3 {*/
/*    font-size: 30px;*/
/*    padding-bottom: 10px;*/
/*    border-bottom: 1px solid var(--ten-color-);*/
/*    color: var(--third-color);*/
/*    text-transform: uppercase;*/
/*    letter-spacing: 5px;*/
/*    font-weight: 500;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced h3 i {*/
/*    font-size: 20px;*/
/*    color: #01a9ac;*/
/*    margin-left: 70%;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced {*/
/*    margin: 10px 0 0 0;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced2 {*/
/*    margin: 10px 0 0 0;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced {*/
/*    width: 100%;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced h4 {*/
/*    font-size: 12px;*/
/*    margin-bottom: 10px;*/
/*    color: var(--eleven-color-);*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced p {*/
/*    color: var(--fifth-color-);*/
/*    font-size: 14px;*/
/*    font-weight: 500;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .fa-pencil {*/
/*    display: none;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .sm-input_reduced {*/
/*    width: 150px;*/
/*    background-color: transparent;*/
/*    border: 2px solid #01a9ac;*/
/*    font-size: 12px;*/
/*    padding: 5px;*/
/*    border-radius: 5px;*/
/*    margin: 5px 5px 0 0;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .sm-input_reduced::placeholder {*/
/*    color: var(--thirteen-color-);*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .sm-input_reduced,*/
/*select,*/
/*textarea {*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .sm-input_reduced:focus {*/
/*    border: 2px solid #019092;*/
/*    outline: none;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .data-input_reduced {*/
/*    width: 350px;*/
/*    background-color: transparent;*/
/*    border: 2px solid #01a9ac;*/
/*    font-size: 18px;*/
/*    padding: 15px;*/
/*    margin: 10px 0 0 0;*/
/*    border-radius: 5px;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .data-input_reduced::placeholder {*/
/*    color: var(--thirteen-color-);*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .data-input_reduced,*/
/*select,*/
/*textarea {*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .data-input_reduced:focus {*/
/*    border: 2px solid #019092;*/
/*    outline: none;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .data-btn_reduced {*/
/*    width: 130px;*/
/*    background: var(--gradient-color2);*/
/*    color: #fff;*/
/*    font-size: 18px;*/
/*    padding: 17px;*/
/*    margin: 10px 0 0 20px;*/
/*    border-radius: 5px;*/
/*}*/

/*.jbox_reduced .right_reduced .info_reduced .info-data_reduced .data_reduced .data-btn_reduced:hover {*/
/*    background: linear-gradient(to right, #00a5a8, #00c4c7);*/
/*}*/

/*.jbox_reduced .pdf_reduced ul {*/
/*    display: flex;*/
/*}*/

/*.jbo_reduced .pdf_reduced ul li {*/
/*    width: 35px;*/
/*    height: 35px;*/
/*    background: var(--gradient-color2);*/
/*    margin-right: 10px;*/
/*    border-radius: 5px;*/
/*    line-height: 35px;*/
/*    text-align: center;*/
/*    color: #fff;*/
/*    cursor: pointer;*/
/*    display: block;*/
/*    font-size: 16px;*/
/*}*/

/*.reduced-btn {*/
/*    display: inline;*/
/*    background: var(--gradient-color2);*/
/*    color: #fff;*/
/*    font-size: 12px;*/
/*    padding: 7px 15px 7px 15px;*/
/*    border-radius: 5px;*/
/*}*/

/*.reduced-btn:hover {*/
/*    background: linear-gradient(to right, #00a5a8, #00c4c7);*/
/*}*/

/*.patients {*/
/*    width: 100%;*/
/*    box-shadow: 10px 10px 15px 1px var(--shadow-color-);*/
/*    border-top-left-radius: 5px;*/
/*    border-top-right-radius: 5px;*/
/*    border-collapse: collapse;*/
/*    text-align: center;*/
/*    overflow: hidden;*/
/*    margin-top: 20px;*/
/*}*/

/*.patients thead {*/
/*    background: var(--gradient-color3);*/
/*    color: #fff;*/
/*}*/

/*.patients th {*/
/*    padding: 12px;*/
/*    text-transform: uppercase;*/
/*    letter-spacing: 0.1rem;*/
/*    font-size: 14px;*/
/*    font-weight: 600;*/
/*}*/

/*.patients td {*/
/*    padding: 12px;*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.patients a {*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*}*/

/*.patients .status {*/
/*    border-radius: 5px;*/
/*    background: var(--status-gradient1);*/
/*    padding: 10px 0px;*/
/*    text-align: center;*/
/*}*/

/*.patients .delivered {*/
/*    background: var(--status-gradient2);*/
/*}*/

/*.patients tr:nth-child(even) {*/
/*    background-color: var(--fourteen-color);*/
/*}*/

/*.pdf-table a {*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    background: var(--gradient-color2);*/
/*    border-radius: 5px;*/
/*    line-height: 26px;*/
/*    text-align: center;*/
/*    color: #fff;*/
/*    cursor: pointer;*/
/*    display: inline-block;*/
/*    font-size: 14px;*/
/*    margin-right: 5px;*/
/*}*/

/*.accordion_reduced {*/
/*    position: relative;*/
/*    width: 100%;*/
/*}*/

/*.accordion_reduced .accordion-content_reduced {*/
/*    position: relative;*/
/*}*/

/*.accordion_reduced .accordion-content_reduced .accordion-label_reduced {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    background: transparent;*/
/*    color: var(--third-color);*/
/*    cursor: pointer;*/
/*    font-size: 14px;*/
/*    font-weight: 600;*/
/*}*/

/*.accordion-label_reduced .fa-angle-down {*/
/*    margin-right: 15px;*/
/*}*/

/*.accordion_reduced .accordion-content_reduced .accordion-box_reduced {*/
/*    position: relative;*/
/*    background: transparent;*/
/*    height: 0;*/
/*    overflow: hidden;*/
/*    overflow-y: auto;*/
/*}*/

/*.accordion_reduced .accordion-content_reduced.active .accordion-box_reduced {*/
/*    height: auto;*/
/*    padding: 10px 10px 25px 10px;*/
/*    border-bottom: 1px solid var(--ten-color-);*/
/*}*/

/*.accordion_reduced .accordion-content_reduced .accordion-box_reduced p {*/
/*    color: var(--twelwe-color-);*/
/*}*/

/*.panel_h3 {*/
/*    font-size: 22px;*/
/*    margin: 25px 0 5px 0;*/
/*    border-bottom: 1px solid var(--ten-color-);*/
/*    color: var(--third-color);*/
/*    text-transform: uppercase;*/
/*    font-weight: 400;*/
/*}*/

/*.panel {*/
/*    margin: 25px 0 0 0;*/
/*}*/

/*.panel-header p {*/
/*    font-size: 14px;*/
/*    color: var(--third-color);*/
/*    text-transform: uppercase;*/
/*    font-weight: 600;*/
/*}*/

/*.panel-body {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-around;*/
/*    align-items: flex-start;*/
/*    background-color: rgba(0, 0, 0, 0.027);*/
/*    padding: 25px 25px 0 25px;*/
/*    border-radius: 25px;*/
/*}*/

/*.panel-body-item {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    padding: 0 0 25px 0;*/
/*}*/

/*.panel-body-item label {*/
/*    padding: 0 25px 0 0;*/
/*    font-weight: 400;*/
/*}*/

/*.panel-body-item select {*/
/*    width: 250px;*/
/*    background-color: rgba(0, 0, 0, 0);*/
/*    border: 1px solid rgba(0, 0, 0, 0.219);*/
/*    border-radius: 5px;*/
/*    padding: 10px 2px 10px 2px;*/
/*    outline: none;*/
/*}*/

/*.panel-body-item span {*/
/*    font-weight: 600;*/
/*}*/

/*.panel-buttons {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    justify-content: space-around;*/
/*}*/

/*.panel-btn {*/
/*    width: 130px;*/
/*    background: #fff;*/
/*    color: #00a5a8;*/
/*    border: 1px solid #00a5a8;*/
/*    font-size: 16px;*/
/*    padding: 9px;*/
/*    margin: 10px 0 0 0;*/
/*    border-radius: 5px;*/
/*}*/

/*.panel-btn:hover {*/
/*    background: #01dbdf17;*/
/*}*/

@media (max-width: 1023px) {
    body {
        height: 100%;
        overflow-y: scroll;
    }

    /*.header {*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: space-between;*/
    /*    position: fixed;*/
    /*    top: 0;*/
    /*    width: 100%;*/
    /*    height: 100px;*/
    /*    background-color: transparent;*/
    /*    z-index: 500;*/
    /*}*/
    /*.header img:nth-child(1) {*/
    /*    width: 13%;*/
    /*    margin-left: 7%;*/
    /*    margin-top: 7%;*/
    /*}*/
    /*.header img:nth-child(2) {*/
    /*    width: 6%;*/
    /*    margin-right: 7%;*/
    /*    margin-top: 7%;*/
    /*}*/
    /*.nav {*/
    /*    display: flex;*/
    /*    position: fixed;*/
    /*    bottom: 0;*/
    /*    width: 100%;*/
    /*    height: 75px;*/
    /*    background-color: var(--secondory-color);*/
    /*    overflow-x: auto;*/
    /*    z-index: 500;*/
    /*}*/
    /*.nav-phone {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    flex-grow: 1;*/
    /*    min-width: 50px;*/
    /*    overflow: hidden;*/
    /*    white-space: nowrap;*/
    /*    font-size: 13px;*/
    /*    color: var(--fourth-color);*/
    /*    text-decoration: none;*/
    /*    -webkit-tap-highlight-color: transparent;*/
    /*    transition: background-color 0.1s ease-in-out;*/
    /*}*/
    /*.nav-phone:hover {*/
    /*    background-color: var(--six-color);*/
    /*}*/
    /*.nav-phone-active {*/
    /*    color: #01ddcb;*/
    /*}*/
    /*.nav-icon {*/
    /*    font-size: 32px;*/
    /*}*/
    .signin {
        background: url('../images/background-girl-media.png');
        background-size: cover;
        background-position: center;
    }

    .jbox-dark-sm {
        background: #0013298c;
    }

    .jbox-dark-md {
        box-sizing: border-box;
        width: 80%;
        height: 80%;
        top: 10%;
        background: #0013298c;
        overflow-y: scroll;
    }

    #register-patient {
        left: 100px;
    }

    #register-physician {
        left: 700px;
    }

    .column {
        display: none;
    }

    .column-phone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .column-phone .input-group-md .input-field {
        font-size: 16px;
        padding: 10px 0;
    }

    .input-group-md .submit-btn {
        margin: 10% auto 10% auto;
    }

    .jbar {
        display: none;
    }

    .jbox {
        position: relative;
        left: 5%;
        top: 170px;
        width: 90%;
        height: 100%;
        display: flex;
        box-shadow: 10px 10px 15px 1px var(--shadow-color-);
    }

    .jbox .left {
        width: 5%;
    }

    .jbox .right {
        width: 95%;
    }

    .jbox .right .info h3 i {
        font-size: 20px;
        color: #01a9ac;
        margin-left: 35%;
    }

    .jbox .right .info .info-data {
        flex-direction: column;
    }

    .jbox .right .info .info-data .data .sm-input {
        margin-bottom: 15px;
    }

    /*.history {*/
    /*    position: relative;*/
    /*    left: 5%;*/
    /*    top: 170px;*/
    /*    width: 90%;*/
    /*    height: 100%;*/
    /*}*/
    /*.cart {*/
    /*    margin: 80px 3% 0 10%;*/
    /*    padding: 30px 10px 0 10px;*/
    /*}*/
}

@media (max-width: 600px) {
    body {
        height: 100%;
        overflow-y: scroll;
        background-color: #000;
    }

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100px;
        background-color: var(--primary-color);
        z-index: 500;
    }

    .header img:nth-child(1) {
        width: 13%;
        margin-left: 7%;
        margin-top: 7%;
    }

    .header img:nth-child(2) {
        width: 6%;
        margin-right: 7%;
        margin-top: 7%;
    }


    .signin {
        background: url(../images/background-girl-media.png);
        background-size: cover;
        background-position: center;
        background-color: #353c4e;
    }

    .jbox-dark-sm {
        width: 100%;
        height: 100%;
        top: 0;
        padding: 15px;
        background: #0013298c;
    }

    .button-box {
        display: none;
    }

    .input-group {
        display: none;
    }

    .button-box-phone {
        display: flex;
    }

    .input-group-phone {
        display: block;
    }

    .jbox-dark-paragraph {
        font-size: 17px;
        line-height: 24px;
    }

    .jbox-dark-md {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        top: 0;
        background: #0013298c;
        overflow-y: scroll;
    }

    #register-patient {
        margin: auto;
        left: 0;
    }

    #register-physician {
        margin: auto;
        left: 600px;
        top: -750px;
    }

    .column {
        display: none;
    }

    .column-phone {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input-group-md {
        position: relative;
        transition: 0.5s;
    }

    .input-group-md .input-field {
        font-size: 16px;
        width: 350px;
        padding: 10px 0;
    }

    .input-group-md .submit-btn {
        margin: 10% auto;
    }

    .jbox-dark-xs {
        width: 100%;
        height: 100%;
        top: 0;
        padding: 25% 15px 15px 15px;
        background: #0013298c;
    }

    .jbar {
        display: none;
    }

    .jbox {
        position: relative;
        left: 5%;
        top: 100px;
        width: 90%;
    }

    .jbox .left {
        width: 5%;
    }

    .jbox .right {
        width: 95%;
    }

    .jbox .right .info h3 {
        font-size: 28px;
    }

    .jbox .right .info h3 i {
        display: none;
    }

    .jbox .right .info .info-data .data .fa-pencil {
        display: block;
        font-size: 20px;
        color: #01a9ac;
    }

    .jbox .right .info .info-data .data h4 {
        font-size: 14px;
    }

    .jbox .right .info .info-data .data p {
        font-size: 16px;
    }

    .jbox .right .info .info-data .data .data-input {
        width: 250px;
        font-size: 16px;
        padding: 10px;
    }

    .jbox .right .info .info-data .data .data-btn {
        font-size: 16px;
        padding: 12px;
        margin: 20px 0 0 0;
    }

}
